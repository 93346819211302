<template>
  <div id="tableReportProducts" class="row reports-table">
    <div class="flex md12 xs12">
      <vac-card> <!-- :title="$t('Last 100 Impressions')"> -->
        <Actions :export-to-excel="exportToExcel"/>
        <div class="row">
          <div class="flex xs12 md3">
            <h1 class="title-table">Product Report</h1>
          </div>
        </div>
        <div class="row align--center">
          <div class="flex xs12 md4">
            <va-input
              :placeholder="$t('Search')"
              :value="searchQuery"
              class="input--border"
              removable
              @input="onSearchQuery">
              <va-icon slot="prepend" name="fa fa-search"/>
            </va-input>
          </div>
        </div>

        <el-table
          ref="mainTable"
          :data="filteredSortedTableDataValues"
          :height="tableHeight"
          style="width: 100%;"
          @expand-change="onExpand"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="Object.values(props.row.licenses)"
                :show-header="false"
                style="width: 100%;"
                @expand-change="onExpand"
              >
                <el-table-column type="expand">
                  <template slot-scope="col">
                    <el-table
                      :data="Object.values(col.row.items)"
                      :show-header="false"
                      class="reports-table__licenses-items"
                      style="width: 100%;"
                    >
                      <!--                PRODUCT -->
                      <el-table-column
                        label="PRODUCT"
                        prop="productName"
                        width="200"
                      >
                        <template slot-scope="props">
                          <div :title="props.row.productName" class="cell__inner">
                            {{ props.row.productName }}
                          </div>
                        </template>
                      </el-table-column>
                      <!--                MPN -->
                      <el-table-column
                        label="MPN"
                        prop="mpn"
                        width="150"
                      >
                        <template slot-scope="props">
                          <div :title="props.row.mpn" class="cell__inner">
                            {{ props.row.mpn }}
                          </div>
                        </template>
                      </el-table-column>

                      <!--                EAN -->
                      <el-table-column
                        label="EAN"
                        prop="ean"
                        width="150"
                      >
                        <template slot-scope="props">
                          <div :title="props.row.ean" class="cell__inner">
                            {{ props.row.ean }}
                          </div>
                        </template>
                      </el-table-column>

                      <!--          RETAILERS -->
                      <template
                        v-for="(retailerName, retailerIndex) in retailers"
                      >
                        <el-table-column
                          :key="retailerIndex"
                          :label="retailerName"
                          width="150"
                        >
                          <template slot-scope="innerProp">
                            <b>
                              {{
                                filteredTableData[props.row.formattedDate]
                                  .licenses[col.row.licenseName]
                                  .items[innerProp.row.epackage_uid]
                                  .count[retailerName]
                              }}
                            </b>
                          </template>
                        </el-table-column>
                      </template>
                      <!--                      TOTAL -->
                      <el-table-column label="Total">
                        <template slot-scope="innerProp">
                          <b>
                            {{
                              Object.values(filteredTableData[props.row.formattedDate]
                                .licenses[col.row.licenseName]
                                .items[innerProp.row.epackage_uid]
                                .count)
                                .reduce((a, c) => a + c, 0)
                            }}
                          </b>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <!--                license -->
                <el-table-column width="150">
                  <template slot-scope="col">{{ col.row.licenseName }}</template>
                </el-table-column>
                <el-table-column
                  label="Product"
                  width="200"
                />
                <!--                MPN -->
                <el-table-column
                  label="MPN"
                  width="150"
                />

                <!--                EAN -->
                <el-table-column
                  label="EAN"
                  width="150"
                />

                <!--          RETAILERS -->
                <template
                  v-for="(retailerName, retailerIndex) in retailers"
                >
                  <el-table-column
                    :key="retailerIndex"
                    :label="retailerName"
                    width="150"
                  >
                    <template slot-scope="col">
                      <b>{{
                          filteredTableData
                            ? (
                              Object.values(filteredTableData[props.row.formattedDate].licenses[col.row.licenseName].items)
                                .map(i => i.count[retailerName])
                                .filter(i => i !== null ? i : 0)
                                .reduce((a, c) => a + c, 0) || ''
                            )
                            : []
                        }}</b>
                    </template>
                  </el-table-column>
                </template>

                <!--                TOTAL -->
                <el-table-column>
                  <template slot-scope="col">
                    <b>
                      {{
                        Object.values(filteredTableData[props.row.formattedDate].licenses[col.row.licenseName].items)
                          .map(i => Object.values(i.count))
                          .flat()
                          .reduce((a, c) => a + c, 0)
                      }}
                    </b>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
            label="Month"
            width="100"
          >
            <template slot-scope="props"><div :title="props.row.formattedDateString">{{ props.row.formattedDateString }}</div></template>
          </el-table-column>

          <!--          Should be empty for the parent table (with expand)-->
          <el-table-column
            label=""
            width="48"
          />
          <el-table-column
            label="License"
            width="150"
          />
          <el-table-column
            label="Product name"
            width="200"
          />
          <el-table-column
            label="MPN"
            width="150"
          />
          <el-table-column
            label="EAN"
            width="150"
          />
          <!--          <el-table-column-->
          <!--            label="EPACK UID"-->
          <!--            width="320"-->
          <!--          />-->

          <!--          RETAILERS -->
          <template
            v-for="(retailerName, retailerIndex) in retailers"
          >
            <el-table-column
              :key="retailerIndex"
              :label="retailerName"
              width="150"
            >
              <template slot-scope="props">
                <b>{{
                    filteredTableData
                      ? (
                        Object.values(filteredTableData[props.row.formattedDate].licenses)
                          .map(i => Object.values(i.items))
                          .flat()
                          .map(i => i.count[retailerName])
                          .filter(i => i !== null ? i : 0)
                          .reduce((a, c) => a + c, 0) || ''
                      )
                      : []
                  }}</b>
              </template>
            </el-table-column>
          </template>

          <el-table-column
            label="Total"
          >
            <template slot-scope="props">
              <b>{{
                  Object.values(props.row.licenses).map(b => Object.values(b.items)).flat().map(v => Object.values(v.count)).flat().reduce((a, c) => a + c, 0)
                }}</b>
            </template>
          </el-table-column>
        </el-table>
      </vac-card>
    </div>
  </div>
</template>

<script>
import HelperExcel from '../../services/Helpers/HelperExcel';
import {Portal} from "portal-vue";
import WarningScreen from "@/components/report/WarningScreen";
import {debounce} from 'lodash';

export default {
  name: 'ReportProductsTable',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },

  components: {
    Actions: () => import('../../components/actions/Actions')
  },

  data() {
    return {
      searchQuery: '',
      tableHeight: '0',
      isContinue: false
    };
  },

  mounted() {
    this.onExpand();
    document.querySelectorAll('.el-table__body-wrapper')?.[0]?.classList.remove('is-scrolling-none')
    document.querySelectorAll('.el-table__body-wrapper')?.[0]?.classList.add('is-scrolling-left')

  },

  watch: {
    filteredTableData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.onExpand()
        })
      }
    }
  },

  computed: {
    filteredTableData() {
      let data = JSON.parse(JSON.stringify(this.data))

      if (this.searchQuery) {
        const searchQueries = this.searchQuery.split(',').map(query => query.trim());
        Object.values(data).forEach(date => {
          Object.entries(date.licenses).forEach(license => {
            const itemsToRemove = []
            Object.entries(license[1].items).forEach(e => {
              const item = e[1]
              let found = false
              searchQueries.forEach(searchQuery => found = found || (item?.mpn?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                item?.ean?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                // !item.epackage_uid.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
                item?.productName?.toLowerCase()?.includes(searchQuery.toLowerCase())))
              if (!found)
                itemsToRemove.push(e[0])
            })
            itemsToRemove.forEach(item => {
              delete license[1].items[item]
            })
            if (!Object.values(license[1].items).length) {
              delete date.licenses[license[0]]
            }
          })
        })
        // eslint-disable-next-line vue/no-async-in-computed-properties
        this.recursiveOpen(0)
      }

      return data ?? {}
    },

    filteredSortedTableDataValues() {
      return Object.values(this.filteredTableData).sort((a, b) => a.sampleDate - b.sampleDate) ?? []
    },

    retailers() {
      const withDuplicates = this.filteredSortedTableDataValues
        .map(i => Object.values(i.licenses))
        .flat()
        .map(i => Object.values(i.items))
        .flat()
        .map(i => Object.keys(i.count))
        .flat()
      return [...new Set(withDuplicates)]
    }
  },

  methods: {
    recursiveOpen(count = 0) {
      if (count >= 2) return
      setTimeout(() => {
        document.querySelectorAll('#tableReportProducts .el-table__expand-icon').forEach(el => {
          if (![...el.classList].includes('el-table__expand-icon--expanded')) {
            el.click()
          }
        })
        this.recursiveOpen(count + 1)
      }, 500)
    },
    parseTitleRow(data) {
      let row = {};
      const {formattedDateString, licenses} = data;
      row['Month'] = formattedDateString;
      row['License'] = '';
      row['Product'] = '';
      row['MPN'] = '';
      row['EAN'] = '';

      const items = []
      Object.values(licenses).map(i => i.items).forEach(i => {
        Object.values(i).forEach(j => items.push(j))
      })
      this.retailers.forEach(key => {
        row[key] = items.reduce((a, c) => {
          let accam = (typeof a === 'object') ? a?.count[key] : a || 0
          if (c?.count[key]) {
            return accam + c?.count[key]
          } else {
            return accam
          }
        }, 0)
      });
      if (Object.values(row).filter(i => typeof i === 'number').length !== 0)
        row['Total'] = Object.values(row).filter(i => typeof i === 'number').reduce((a, c) => a + c, 0);
      return row;
    },

    parseLicenseRow(data) {
      let row = {};
      const {licenseName, items} = data;
      row['Month'] = '';
      row['License'] = licenseName;
      row['Product'] = '';
      row['MPN'] = '';
      this.retailers.forEach(key => {
        if (Object.values(items).length === 1) {
          row[key] = Object.values(items)[0]?.count[key]
        } else {
          row[key] = Object.values(items).reduce((a, c) => {
            let accam = (typeof a === 'object') ? a.count[key] : a || 0
            if (c.count[key]) {
              return accam + c.count[key]
            } else {
              return accam
            }
          }, 0)
        }

      });
      if (Object.values(row).filter(i => typeof i === 'number').length !== 0)
        row['Total'] = Object.values(row).filter(i => typeof i === 'number').reduce((a, c) => a + c, 0);
      return row;
    },

    onExpand() {
      this.$nextTick(() => {
        const tableHeight = this.$refs.mainTable.$el.querySelector('.el-table__body-wrapper table').scrollHeight + 60
        this.tableHeight = tableHeight > window.innerHeight - 200 ? window.innerHeight - 200 : tableHeight
      })
    },

    parseProductRow(product) {
      const {mpn, ean, count, productName} = product;
      return {
        MPN: mpn,
        EAN: ean,
        Product: productName,
        ...count,
        Total: Object.values(count).filter(i => typeof i === 'number').reduce((a, c) => a + c, 0)
      }
    },

    exportToExcel() {
      const labels = ['Month', 'License', 'Product', 'MPN', 'EAN', ...this.retailers, 'Total'];
      const data = [];
      const columnWidth = [];
      Object.values(this.filteredTableData).forEach(monthRow => {
        data.push(this.parseTitleRow(monthRow));

        Object.values(monthRow.licenses).forEach(license => {
          data.push(this.parseLicenseRow(license));
          Object.values(license.items).forEach(product => {
            data.push(this.parseProductRow(product));
          })
        });

        data.push({});
      });

      labels.forEach(() => {
        columnWidth.push(6);
      });

      HelperExcel.exportToExcel({}, 'Reports Product', labels, data, columnWidth);
    },

    onSearchQuery: debounce(function (value) {
      this.searchQuery = value;
    }, 400),

    continueCreating() {
      this.isContinue = !this.isContinue;
    },
    disable() {
      this.isContinue = !this.isContinue;
    }
  },
};
</script>

<style lang="scss">
.reports-table {
  h1 {
    white-space: nowrap;
  }

  th {
    overflow: visible;
    text-overflow: unset;
    max-width: 200px;
    min-width: 200px !important;
  }
  td {
    max-width: 200px;
    min-width: 200px !important;
  }

  th.el-table_1_column_1 {
    max-width: 50px;
    min-width: 50px !important;
  }

  th.el-table_1_column_3 {
    max-width: 50px;
    min-width: 50px !important;
  }

  td.el-table_1_column_1 {
    max-width: 50px;
    min-width: 50px !important;
  }

  td.el-table_1_column_3 {
    max-width: 50px;
    min-width: 50px !important;
  }

  th.el-table__expand-column.el-table__cell,
  td.el-table__expand-column.el-table__cell {
    max-width: 50px;
    min-width: 50px !important;
  }

  .cell,
  .cell__inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
  }

  .el-table__expanded-cell[class*=cell] {
    padding: 0 0 20px 250px;

    .el-table__body-wrapper {
      overflow: hidden;
    }
  }
}

.el-table__body,
.el-table__header,
.el-table__footer {
  table-layout: unset;
}

.report-window {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);

  &-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f4f8;
    border-radius: 6px;
    transform: translateX(calc(50vw - 212px)) translateY(calc(50vh - 177px));
    position: absolute;
    opacity: 1;
    width: 424px;
  }
}
.el-table__body-wrapper {
  overflow: scroll;
}
</style>
